var $=jQuery.noConflict();

$(document).ready(function(){

    // Contact
    $('.wpcf7-form .wpcf7-text').addClass('form-control');
    $('.wpcf7-textarea').addClass('form-control');
    $('.wpcf7-date').addClass('form-control');
    $('.wpcf7-submit').addClass('btn btn-primary font-weight-bold text-uppercase');
    //$('.input-xlarge').addClass('form-control');


});
